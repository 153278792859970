import { React, useEffect, useState } from "react";
import './ESGDetail.css';
import { collection, getDocs, query, orderBy, where  } from "firebase/firestore"; // 데이터 읽어오기


function ESGDetail(props_sub) {

  const [ESGDb, SetESGDb]=useState(null);
  const [ESGBA, SetESGBA]=useState(null);    
  const [ESGDbLoading, SetESGDbLoading]=useState(false);
  const [ESGBALoading, SetESGBALoading]=useState(false);

  useEffect(()=>{
    LoadingESGBanner(props_sub.ESGType);
    LoadingESGData(props_sub.ESGType);
  },[])

  useEffect(()=>{
    LoadingESGBanner(props_sub.ESGType);
    LoadingESGData(props_sub.ESGType);
  },[props_sub.ESGType])
  
  async function LoadingESGBanner(ESGType){    
    SetESGBALoading(false);
    const UserListRef = collection(props_sub.db, "ESGBanner"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    let querySnapshot = await getDocs(query(UserListRef, orderBy('No', 'asc'), where("Type", "==", ESGType)));
    let ESGBanner=[];
    await querySnapshot.forEach((doc) => {
      ESGBanner.push(doc.data());
    });
    SetESGBA(ESGBanner);
    console.log(ESGBanner);
    SetESGBALoading(true);
  }

  async function LoadingESGData(ESGType){    
    SetESGDbLoading(false);
    const UserListRef = collection(props_sub.db, "ESG"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    let querySnapshot = await getDocs(query(UserListRef, orderBy('No', 'asc'), where("Type", "==", ESGType)));
    let ESGData=[];
    await querySnapshot.forEach((doc) => {
      ESGData.push(doc.data());
    });
    SetESGDb(ESGData);
    console.log(ESGData);
    SetESGDbLoading(true);
  }

  return (
    <div className="ESGDetail flex w-full flex-col">
      <div className="ESGDetail_PC flex w-full flex-col">
        <div className="flex Title mt-20">{props_sub.ESGType}</div>
        <div className="ESGBar flex w-full h-1 mt-1 mb-1"></div>
        {ESGBALoading && ESGBA.length ?
         <div className="flex w-full">
          {ESGBA.map((info, idx)=>(
          <div className="ESGComment flex w-full gap-4">
            <div className="flex ESGIMG" key={idx}> <img src={info.IMG} alt={info.IMGalt}></img> </div>
            <div className="flex flex-1"> <p>{info.Comment}</p></div>
          </div>
          ))}
        </div> : null }

        {ESGDbLoading && ESGDb.length ?
        <div className="ESG_PC-CI con flex w-full items-center flex-col mt-20 mb-40">
          <div className="ESGReport flex mb-10">Report</div>
          {ESGDb.map((info, idx)=>(
          <div className="ESG_PC-CI-lists flex w-full" key={idx}>
            <a className="flex w-full items-center gap-4" href={info.LINK} target="_blank" rel="noopener noreferrer">
              <div className="flex">{idx+1}</div>
              <div className="flex flex-1">{info.Title}</div>
              <div className="flex"><i className="fa-solid fa-download"></i></div>
            </a>
          </div>
          ))}
        </div>
        : 
        <div className="ESG_PC-CI con flex w-full items-center flex-col mt-20 mb-40">
          <div className="ESGReport flex mb-10">Report</div>
          <div className="flex text-xl">등록된 보고서가 없습니다.</div>
        </div>
        }
        
      </div>
      <div className="ESGDetail_MB flex w-full flex-col">
        <div className="flex Title mt-16">{props_sub.ESGType}</div>
        <div className="ESGBar flex w-full h-1 mt-1 mb-1"></div>
        {ESGBALoading && ESGBA.length ?
         <div className="flex w-full">
          {ESGBA.map((info, idx)=>(
          <div className="ESGComment flex w-full flex-col gap-4">
            <div className="flex ESGIMG" key={idx}> <img src={info.IMG} alt={info.IMGalt}></img> </div>
            <div className="flex flex-1"> <p>{info.Comment}</p></div>
          </div>
          ))}
        </div> : null }
        {ESGDbLoading && ESGDb.length ?
        <div className="ESG_PC-CI flex w-full items-center flex-col mt-20 mb-40">
          <div className="ESGReport flex mb-10">Report</div>
          {ESGDb.map((info, idx)=>(
          <div className="ESG_PC-CI-lists flex w-full" key={idx}>
            <a className="flex w-full items-center gap-4" href={info.LINK} target="_blank" rel="noopener noreferrer">
              <div className="flex">{idx+1}</div>
              <div className="flex flex-1">{info.Title}</div>
              <div className="flex"><i className="fa-solid fa-download"></i></div>
            </a>
          </div>
          ))}
        </div>
        : 
        <div className="ESG_PC-CI con flex w-full items-center flex-col mt-20 mb-40">
          <div className="ESGReport flex mb-10">Report</div>
          <div className="flex text-xl">등록된 보고서가 없습니다.</div>
        </div>
        }
        
      </div>
    </div>
  );
}

export default ESGDetail;