import { React, useEffect, useState } from "react";
import './Location.css';
import { collection, getDocs, query, orderBy } from "firebase/firestore"; // 데이터 읽어오기

function Location(props) {
  const [InfoDb, SetInfoDb]=useState(null);
  let InfoData=[];
  const [InfoLoading, SetInfoLoading]=useState(false);

  useEffect(()=>{
    LoadingInfoData();
  },[])

  async function LoadingInfoData(){
    SetInfoLoading(false);
    const UserListRef = collection(props.db, "Info"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(query(UserListRef, orderBy("No", "asc")));
    InfoData.length=0;
    await querySnapshot.forEach((doc) => {
      InfoData.push(doc.data());
    });
    SetInfoDb(InfoData);
    SetInfoLoading(true);
  }

  return (
    <div className="Location">
      <div className="Location_PC flex flex-col w-full items-center">
        <div className="Location_PC-banner flex flex-col w-full items-center">
          <div className="flex con w-full h-full flex-col justify-end">
            <div className="Location_PC-banner-top flex flex-col w-full justify-end">
              <div>BUSINESS<br></br>PLACE</div>
              <div className="text-2xl pl-2">사업장 소개</div>
            </div>
            <div className="flex h-20"></div>
            <div className="flex h-7"></div>
          </div>
        </div>
        {InfoLoading ?
        <div className="Location_PC-CI con flex w-full flex-col">
        {InfoDb.map(info=>(
          <div className="Location_PC-CI-lists flex flex-col w-full items-center" key={info.index}>
            <div className="flex h-20"></div>
            <div className="flex w-full mb-5">{info.Name}</div>
            <div className="flex w-full">
              <img className="flex w-full" src={info.IMG} alt={info.Name}></img>
            </div>
            <div className="flex w-full mt-1 mb-1 text-2xl">주소</div>
            <p className="flex w-full">{info.Location}</p>
            <div className="flex h-10"></div>
            <div className="flex w-full items-end">
              <span className="flex">Tel &#41;</span>
              <p className="flex ml-3">{info.Tel}</p>
              <div className="flex flex-1"></div>
              <span className="flex">Fax &#41;</span>
              <p className="flex ml-3">{info.Fax}</p>
              <div className="flex flex-1"></div>
            </div>
          </div>
        ))}
        </div>
        : null}
        <div className="flex h-20"></div>
        <div className="flex h-10"></div>
      </div>

      <div className="Location_MB flex flex-col w-full items-center">
        <div className="Location_MB-banner flex flex-col w-full items-center">
          <div className="flex w-full h-full flex-col justify-end">
            <div className="Location_MB-banner-top flex flex-col w-full justify-end">
              <div>COMPANY<br></br>INTRODUCTION</div>
              <div className="text-xl pl-2">사업장 소개</div>
            </div>
            <div className="flex h-14"></div>
            <div className="flex h-7"></div>
          </div>
        </div>
        {InfoLoading ?
        <div className="Location_MB-CI flex w-full flex-col">
        {InfoDb.map(info=>(
          <div className="Location_MB-CI-lists flex flex-col w-full items-center" key={info.index}>
            <div className="flex h-16"></div>
            <div className="flex w-full mb-5">{info.Name}</div>
            <div className="flex w-full">
              <img className="flex w-full" src={info.IMG} alt={info.Name}></img>
            </div>
            <div className="flex w-full mt-1 mb-1 text-xl">주소</div>
            <p className="flex w-full">{info.Location}</p>
            <div className="flex h-4"></div>
            <div className="flex flex-col w-full">
              <div className="flex mb-1">
                <span className="flex">Tel &#41;</span>
                <p className="flex ml-2">{info.Tel}</p>
              </div>
              <div className="flex">
                <span className="flex">Fax &#41;</span>
                <p className="flex ml-2">{info.Fax}</p>
              </div>
            </div>
          </div>
        ))}
        </div>
        : null}
        <div className="flex h-20"></div>
        <div className="flex h-10"></div>
      </div>

      
    </div>
  );
}

export default Location;
