import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import './Contact.css';

import { getFirestore, collection, getDocs, doc } from "firebase/firestore"; // 데이터 읽어오기

function Contact(props) {
  const [CompanyDb, SetCompanyDb]=useState(null);
  let CompanyData=[];
  const [CompanyLoading, SetCompanyLoading]=useState(false);

  useEffect(()=>{
    LoadingData();
  },[])

  async function LoadingData(){
    SetCompanyLoading(false);
    const UserListRef = collection(props.db, "Info"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(UserListRef);
    CompanyData.length=0;
    await querySnapshot.forEach((doc) => {
      if(CompanyData.length<1){
        CompanyData.push(doc.data());
      }
    });
    SetCompanyDb(CompanyData);
    SetCompanyLoading(true);
  }

  return (
    <div className="Contact">
      {CompanyLoading ?
        <div className="Contact_PC flex flex-col h-full">
          <div className="Contact_PC-top flex flex-col w-full justify-start">
            <div className="flex">Contact</div>
            <div className="flex h-8"></div>
          </div>
          {CompanyDb.map(company=>(
          <div className="flex w-full items-start" key={company}>
            <div className="flex w-16"></div>
            <div className="Contact_PC-left-info flex flex-col items-start">
              <div className="flex">Phone</div>
              <p className="flex text-2xl">{company.Tel}</p>
              <div className="flex h-7"></div>
              <div className="flex">Email</div>
              <p className="flex text-2xl">{company.Email}</p>
              <div className="flex h-7"></div> 
            </div>
          </div>
          ))}
        </div>
      : null}

      {CompanyLoading ?
      <div className="Contact_MB flex flex-1 flex-col">
        <div className="flex h-10"></div>
        {CompanyDb.map(company=>(
          <div className="Contact_MB-top flex flex-1 flex-col" key={company}>
            <div className="flex">Contact</div>
            <div className="flex h-7"></div>
            <div className="flex">Phone</div>
            <p className="flex">{company.Tel}</p>
            <div className="flex h-7"></div>
            <div className="flex">Email</div>
            <p className="flex">{company.Email}</p>
          </div>
        ))}
        <div className="h-20"></div>
      </div>
      : null }
    </div>
  );
}

export default Contact;
