import { Routes, Route, useLocation } from "react-router-dom";
import { React, useEffect, useState } from "react";
import './App.css';

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"; // 데이터 읽어오기

import Menu from './Module/Menu.js';
import Footer from './Module/Footer.js';
import Home from "./pages/Home.js"
import Product from "./pages/Product.js"
import Process from "./pages/Process.js"
import Facility from "./pages/Facility.js"
import Research from "./pages/Research.js"
import Patent from "./pages/Patent.js"
import AboutUs from "./pages/AboutUs.js"
import ESG from "./pages/ESG.js"
import Location from "./pages/Location.js"
import Contact from "./pages/Contact.js"
import News from "./pages/News.js"
import Archaive from "./pages/Archaive.js"

function App() {

  // FireBase, TODO: Replace the following with your app's Firebase project configuration
  // See: https://support.google.com/firebase/answer/7015592
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
  };
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  return (
    <div className="App">

      <div className="Top-Menu-Fix">
        <Menu/>
      </div>
      <Routes>
          <Route exact path="" element={<Home db={db} />} />
          <Route exact path="/AboutUs" element={<AboutUs db={db} />} />

          <Route exact path="/Environment" element={<ESG db={db} ESG={"Environment"}/>} />
          <Route exact path="/Social" element={<ESG db={db} ESG={"Social"}/>} />
          <Route exact path="/Governance" element={<ESG db={db} ESG={"Governance"}/>} />

          <Route exact path="/Location" element={<Location db={db} />} />
          <Route exact path="/Product" element={<Product db={db} />} />
          <Route exact path="/Process" element={<Process db={db} />} />
          <Route exact path="/Facility" element={<Facility db={db} />} />
          <Route exact path="/Patent" element={<Patent db={db} />} />
          <Route exact path="/Research" element={<Research db={db} />} />
          <Route exact path="/Contact" element={<Contact db={db} />} /> 
          <Route exact path="/News" element={<News db={db} />} /> 
          <Route exact path="/Archaive" element={<Archaive db={db} />} /> 
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
