import { React, useEffect, useRef, useState } from "react";

import './ESG.css';
import ESGDetail from './ESGPages/ESGDetail.js';

import { Link } from "react-router-dom";
import { collection, getDocs, query, where} from "firebase/firestore"; // 데이터 읽어오기

function ESG(props) {  // props.ESG 서비스 페이지의 필터링 키워드 2
  const [BannerDb, SetBannerDb] = useState(null);
  const [BannerLoading, SetBannerLoading] = useState(false);
  
  useEffect(()=>{
    LoadingBannerData(props.ESG);
  },[])

  useEffect(()=>{
    LoadingBannerData(props.ESG);
  },[props.ESG])

  async function LoadingBannerData(ESGType){
    SetBannerLoading(false);
    const UserListRef = collection(props.db, "Banner"); // 특정 컬렉션의 특정 문서 내의 컬렉션 읽기
    const querySnapshot = await getDocs(query(UserListRef, where("Page", "==", "ESG"), where("Type", "==", ESGType)));
    let Banner = [];
    await querySnapshot.forEach((doc) => {
      Banner.push(doc.data());
    });
    SetBannerDb(Banner);
    SetBannerLoading(true);
  }

  return (
    <div className="ESG">
      <div className="ESG_PC flex w-full flex-1 items-center flex-col">
        <div className="ESG_PC-banner flex flex-col w-full items-center">
          <div className="flex con w-full h-full flex-col justify-center">
            <div className="ESG_PC-banner-top flex flex-col w-full justify-center">
              <div className="flex justify-center">E S G</div>
              <div className="text-2xl pl-2 flex justify-center">환경보호 · 사회공헌 · 윤리경영</div>
              <div className="text-xl pl-2 flex justify-center mt-2">신아화스텍은 지속 가능한 성장 달성을 목표로 ESG를 실천합니다</div>
            </div>
          </div>
        </div>
        <div className="ESG_PC-ESGs w-full items-center flex flex-col">
          <div className="MenuAreaList flex w-full con">
            <ul className="flex w-full">
              <li className="flex flex-1">
                <Link to="/Environment" className="flex flex-1 w-full h-full items-center justify-center">Environment</Link>
              </li>
              <li className="flex flex-1">
                <Link to="/Social" className="flex flex-1 w-full h-full items-center justify-center">Social</Link>
              </li>
              <li className="flex flex-1">
                <Link to="/Governance" className="flex flex-1 w-full h-full items-center justify-center">Governance</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="ContentList flex w-full con">
          <ESGDetail db={props.db} ESGType={props.ESG} />
        </div>
      </div>

      <div className="ESG_MB flex w-full flex-1 items-center flex-col">
        <div className="ESG_MB-banner flex flex-col w-full items-center">
          <div className="flex w-full h-full flex-col justify-center">
            <div className="ESG_MB-banner-top flex flex-col w-full justify-center">
              <div className="flex justify-center">E S G</div>
              <div className="text-xl flex justify-center">환경보호 · 사회공헌 · 윤리경영</div>
              <div className="text-base flex justify-center text-center mt-2">신아화스텍은 지속 가능한<br></br>성장 달성을 목표로 ESG를 실천합니다</div>
            </div>
          </div>
        </div>
        <div className="ESG_MB-ESGs w-full items-center flex flex-col">
          <div className="MenuAreaList flex w-full con">
            <ul className="flex w-full">
              <li className="flex flex-1">
                <Link to="/Environment" className="flex flex-1 justify-center items-center">E</Link>
              </li>
              <li className="flex flex-1">
                <Link to="/Social" className="flex flex-1 justify-center items-center">S</Link>
              </li>
              <li className="flex flex-1">
                <Link to="/Governance" className="flex flex-1 justify-center items-center">G</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="ContentList flex w-full">
          <ESGDetail db={props.db} ESGType={props.ESG} />
        </div>
      </div>
    </div>
  );
}

export default ESG;
